const routes = {
  home: '/',
  apiKeys: '/api-keys',
  consumerKeys: '/consumer-keys',
  apiSettings: '/apisettings',
  sdkSettings: '/sdksettings',
  consumerSettings: '/consumersettings',
  supportFiles: '/support-files',
  reports: '/reports',
  reportDetail: '/feature',
  terms: '/terms-conditions',
  logs: '/logs',
  health: '/health',
  transactions: '/transactions'
};

export default routes;
