import axios from "../common/api/custom-axios";
import configs from "../configs";

export interface FiltersData {
    consumerSetting: ClientData[]
}

export interface ClientData {
    token: string,
    name: string,
}

export interface ConsumerSetting {
    id: string,
    consumerKey: string,
    exchange: string,
    bindingKey: string
}

const getFilters = async (): Promise<FiltersData> => {
    const res = await axios.get<FiltersData>(`${configs.endpoint}/consumer-settings/filters`);
    return res.data;
};

const queryData = async (consumerKey?: string): Promise<ConsumerSetting[]> => {
    const res = await axios.get<ConsumerSetting[]>(`${configs.endpoint}/consumer-settings`, {
        params: {
            consumerKey,
        }
    });

    return res.data;
}

const deleteSetting = async (setting: ConsumerSetting): Promise<void> => {
    await axios.delete(`${configs.endpoint}/consumer-settings/${setting.consumerKey}/${setting.id}`);
}

const addSetting = async (setting: ConsumerSetting): Promise<void> => {
    await axios.post(`${configs.endpoint}/consumer-settings`, {
        consumerKey: setting.consumerKey,
        exchange: setting.exchange,
        bindingKey: setting.bindingKey
    });
}

const service = {
    queryData,
    deleteSetting,
    addSetting,
    getFilters
}

export default service;