import {useEffect, useState} from "react";
import {RevocationList} from "../common/models/files-data";
import {Button, Col, Container, Form, FormGroup, Row, Table} from "reactstrap";
import {FileInput} from "../../common/components";

interface RevocationListsProps {
    apiKey: string
    currentRevocation?: RevocationList
    onSubmit: (revocation: File) => void;
    onDelete: (revocation: RevocationList) => void;
}

export const RevocationLists = (props: RevocationListsProps) => {
    const [revocation, setRevocation] = useState<File | undefined>(undefined);

    const isFormValid = (): boolean => {
        return revocation !== undefined;
    }

    const resetForms = () => {
        setRevocation(undefined)
    }

    const onUpload = () => {
        if (revocation !== undefined) {
            props.onSubmit(revocation)
            resetForms()
        }
    }

    const onDelete = (revocation: RevocationList | undefined) => {
        if (revocation !== undefined) {
            props.onDelete(revocation)
        }
    }

    useEffect(() => {
        resetForms()
    }, [props.apiKey]);

    return (
        <Container>
            <Form>
                <Row form>
                    <Col sm={3}>
                        <FormGroup>
                            <FileInput label="Upload new Revocation List:" accept=".crl"
                                       onFileSelected={setRevocation}/>
                        </FormGroup>
                    </Col>
                    <Col sm={3} className="align-self-end">
                        <FormGroup>
                            <Button color="primary" disabled={!isFormValid()} onClick={() => onUpload()}>Upload</Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <Form>
                <Row form>
                    <Col>
                        <Table bordered responsive size="lg">
                            <thead>
                            <tr>
                                <th key={'file'} className={"sticky-begin"}>{"File"}</th>
                                <th key={'actions'} className={"table-last-col"}>{"Actions"}</th>
                            </tr>
                            </thead>
                            {!props.currentRevocation &&
                                <tbody>
                                <tr>
                                    <td colSpan={3}>No Data Available</td>
                                </tr>
                                </tbody>
                            }
                            {props.currentRevocation &&
                                <tbody>
                                <tr key={props.currentRevocation.path}>
                                    <td key={props.currentRevocation.path + '-path'} className={"sticky-begin"}>
                                        <div className={"table-col"}>{<a href={props.currentRevocation.path}
                                                                         rel="noreferrer">{props.currentRevocation.path}</a>}</div>
                                    </td>

                                    <td key={props.currentRevocation.path + '- action'}
                                        className="sticky-end table-last-col">
                                        <div className={"table-last-col-content"}><Button color="default"
                                                                                          onClick={() => onDelete(props.currentRevocation)}><i
                                            className="fa fa-trash"></i></Button></div>
                                    </td>
                                </tr>
                                </tbody>
                            }
                        </Table>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}