import { FC } from "react";
import { Col, Form, Row } from "reactstrap";
import { SelectorFormGroup } from "../common/components";
import { ClientData } from "../sdk-settings/SdkSettingsService";

interface FormParams {
    consumerKeysList: ClientData[];
    currentConsumerKey: string;
    onConsumerKeyChange: (consumerKey: string) => void;
}

const ConsumerSettingsForm: FC<FormParams> = (form: FormParams) => {
    const transformConsumerKeyList = () => {
        const list = form.consumerKeysList?.map(c => ({
            text: c.name,
            value: c.token
        })) || [];

        if (list) {
            list.unshift({ text: "All", value: "" });
            return list;
        }

        return [];
    }

    return (
        <Form>
            <Row form>
                <Col sm={3}>
                    <SelectorFormGroup
                        title="Client"
                        id="consumerKeySelect"
                        values={transformConsumerKeyList()}
                        onChange={(value) => form.onConsumerKeyChange(value)}
                        value={form.currentConsumerKey}
                    />
                </Col>
            </Row>
        </Form>
    )
};

export default ConsumerSettingsForm;
