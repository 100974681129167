import { FC, useState } from "react";
import { Button, Container, Table } from "reactstrap";
import { ConsumerSetting } from "./ConsumerSettingsService";
import ConsumerSettingsNewModal from "./ConsumerSettingsNewModal";

const columns = [
    "consumerKey",
    "exchange",
    "bindingKey"
];

const emptySetting = {
    id: '',
    consumerKey: '',
    exchange: '',
    bindingKey: ''
}

interface TableData {
    settings: ConsumerSetting[]
    onSettingDelete: (setting: ConsumerSetting) => void
    onSettingSubmit: (setting: ConsumerSetting) => void
}

const createHeaders = (headers: string[]) => (
    <thead>
        <tr>
            {headers.map(c => (<th key={`${c}-key`}>{c}</th>))}
            <th className="sticky-end">delete</th>
        </tr>
    </thead>
);

const mapSettings = (settings: ConsumerSetting[], onActionClick: (setting: ConsumerSetting) => void) => settings.map((row, i) => (
    <tr key={i}>
        <td key={`${i}-consumerKey`}>{row.consumerKey}</td>
        <td key={`${i}-exchange`}>{row.exchange}</td>
        <td key={`${i}-bindingKey`}>{row.bindingKey}</td>
        <td key={`${i}-delete`} className="sticky-end">
            <Button color="default" onClick={() => onActionClick(row)}>
                <i className="fa fa-trash"></i>
            </Button>
        </td>
    </tr>
));

const ConsumerSettingsTable: FC<TableData> = (data: TableData) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <>
            <Button className="generate-button" color="primary" onClick={() => setIsModalOpen(true)}>Add Setting</Button>
            <Container>
                <Table bordered responsive striped size="lg">
                    {createHeaders(columns)}
                    <tbody>
                        {mapSettings(data.settings, (setting) => {
                            data.onSettingDelete(setting);
                        })}
                    </tbody>
                </Table>
                {isModalOpen &&
                    <ConsumerSettingsNewModal
                        onSubmit={data.onSettingSubmit}
                        setting={emptySetting}
                        onClose={() => {
                            setIsModalOpen(false);
                        }}
                    />
                }
            </Container>
        </>
        
    )
};

export default ConsumerSettingsTable;