import {FC, useEffect, useState} from "react";
import {Container, Form, Row, FormGroup, Col, Label, Input, Spinner} from "reactstrap";
import {FilesData, RegulaLicense, CSCACertificate, RevocationList} from "./common/models/files-data";
import SupportFilesService from "./common/services/support-files.service";
import ToastService from "../common/services/toast-service";
import {Licenses} from "./licenses/Licenses";
import {CscaCertificates} from "./csca-certificates/CscaCertificates";
import {RevocationLists} from "./revocation/RevocationLists";

const SupportFiles: FC = () => {
    const [files, setFiles] = useState<FilesData>();
    const [selectedApiKey, SetSelectedApiKey] = useState<string>("");

    const getFilesData = async () => {
        try {
            const data = await SupportFilesService.getClientsData();
            setFiles(data);
        } catch (error: any) {
            ToastService.showToast("Error fetching files.", error.message, false);
        }
    };

    const uploadLicense = async (file: File, expiryDate: string) => {
        try {
            await SupportFilesService.uploadLicense(selectedApiKey, {file, expiryDate});
            ToastService.showToast("Success", "Uploaded license file", true);
            await getFilesData();
        } catch (error: any) {
            ToastService.showToast("Error uploading license file", error.message);
        }
    };

    const uploadCertificates = async (file: File) => {
        try {
            await SupportFilesService.uploadCertificates(selectedApiKey, {file});
            ToastService.showToast("Success", "Uploaded certificate", true);
            await getFilesData();
        } catch (error: any) {
            ToastService.showToast("Error uploading certificate", error.message);
        }
    };

    const uploadRevocation = async (file: File) => {
        try {
            await SupportFilesService.uploadRevocation(selectedApiKey, {file});
            ToastService.showToast("Success", "Uploaded Revocation List", true);
            await getFilesData();
        } catch (error: any) {
            ToastService.showToast("Error uploading Revocation List", error.message);
        }
    }

    const deleteLicense = async () => {
        try {
            await SupportFilesService.deleteLicense(selectedApiKey);
            ToastService.showToast("Success", "Deleted license file", true);
            await getFilesData();
        } catch (error: any) {
            ToastService.showToast("Error deleting license file", error.message);
        }
    };

    const deleteCertificate = async (apiKey: string, certificate: CSCACertificate) => {
        try {
            await SupportFilesService.deleteCertificate(apiKey, certificate.id);
            ToastService.showToast("Success", "Deleted certificate", true);
            await getFilesData();
        } catch (error: any) {
            ToastService.showToast("Error deleting certificate", error.message);
        }
    }

    const deleteRevocation = async () => {
        try {
            await SupportFilesService.deleteRevocation(selectedApiKey);
            ToastService.showToast("Success", "Deleted Revocation List", true);
            await getFilesData();
        } catch (error: any) {
            ToastService.showToast("Error deleting Revocation List", error.message);
        }
    };

    const getCurrentLicense = (): RegulaLicense | undefined =>
        files?.clientData.find(data => data.apiKeyToken === selectedApiKey)?.regulaLicense;

    const getCurrentCertificates = (): CSCACertificate[] | undefined =>
        files?.clientData.find(data => data.apiKeyToken === selectedApiKey)?.cscaCertificates;

    const getCurrentRevocation = (): RevocationList | undefined =>
        files?.clientData.find(data => data.apiKeyToken === selectedApiKey)?.revocationList;

    useEffect(() => {
        getFilesData();
    }, []);

    const spinner = () => (
        <Container>
            <Spinner color="primary"/>
        </Container>
    );

    const form = () => (
        <>
            <Form>
                <h5>Upload support files</h5>
                <Row form>
                    <Col sm={3}>
                        <FormGroup>
                            <Label for="apiKeySelect">Select client:</Label>
                            <Input type="select" name="select" id="apiKeySelect"
                                   defaultValue={''}
                                   onChange={e => SetSelectedApiKey(e.target.value)}>
                                <option value="" hidden></option>
                                {files?.clientData.map(clientData =>
                                    <option key={clientData.apiKeyToken}
                                            value={clientData.apiKeyToken}>{clientData.apiKeyName}</option>
                                )}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            {selectedApiKey && (
                <Container>
                    <Licenses
                        apiKey={selectedApiKey}
                        currentLicense={getCurrentLicense()}
                        onDelete={() => deleteLicense()}
                        onSubmit={(lic, expiryDate) => uploadLicense(lic, expiryDate)}
                    />
                    <CscaCertificates
                        apiKey={selectedApiKey}
                        currentCertificates={getCurrentCertificates()}
                        onDelete={(certificate) => deleteCertificate(selectedApiKey, certificate)}
                        onSubmit={(certs) => uploadCertificates(certs)}/>
                    <RevocationLists
                        apiKey={selectedApiKey}
                        currentRevocation={getCurrentRevocation()}
                        onDelete={() => deleteRevocation()}
                        onSubmit={(revo) => uploadRevocation(revo)}
                    />
                </Container>
            )}
        </>
    )

    return <Container>{files ? form() : spinner()}</Container>;
};

export default SupportFiles;