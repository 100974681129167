/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Spinner } from "reactstrap";
import ToastService from "../common/services/toast-service";
import ConsumerSettingsForm from "./ConsumerSettingsForm";
import ConsumerSettingsService, { ConsumerSetting, ClientData } from "./ConsumerSettingsService";
import ConsumerSettingsTable from "./ConsumerSettingsTable";

const ConsumerSettings = () => {
    const [consumerList, setConsumerList] = useState<ClientData[]>([]);
    const [currentConsumer, setCurrentConsumer] = useState<string>("");
    const [data, setData] = useState<ConsumerSetting[]>([]);

    const getData = async (): Promise<ConsumerSetting[] | undefined> => {
        try {
            const data = await ConsumerSettingsService.queryData(currentConsumer);
            setData(data);
            return data;
        } catch (error: any) {
            ToastService.showToast("Error getting queried data", error.message);
        }
    }

    useEffect(() => {
        ConsumerSettingsService.getFilters()
            .then((data) => setConsumerList(data.consumerSetting))
            .catch(error => ToastService.showToast("Error getting filters", error));
    }, []);

    useEffect(() => {
        getData();
    }, [currentConsumer]);

    const onSettingDelete = async (setting: ConsumerSetting) => {
        try {
            await ConsumerSettingsService.deleteSetting(setting);
            await getData();
            ToastService.showToast(setting.id, "Setting deleted successfully", true);
        } catch (error: any) {
            ToastService.showToast("Error deleting setting", error.message);
        }
    }

    const onSettingSubmit = async (setting: ConsumerSetting) => {
        try {
            await ConsumerSettingsService.addSetting(setting);
            await getData();
            ToastService.showToast(setting.id, "Setting added successfully", true);
        } catch (error: any) {
            ToastService.showToast("Error adding setting", error.message);
        }
    }

    return (
        <Container>
            <Container>
                <ConsumerSettingsForm
                        consumerKeysList={consumerList}
                        currentConsumerKey={currentConsumer}
                        onConsumerKeyChange={(v) => setCurrentConsumer(v)}/>
            </Container>
            {data ? <ConsumerSettingsTable
                    settings={data}
                    onSettingDelete={onSettingDelete}
                    onSettingSubmit={onSettingSubmit} /> : <Spinner color="primary" />}
        </Container>
    )
};

export default ConsumerSettings;
