import { AxiosInstance } from 'axios';
import authenticator from './authenticator';

export function setupAxiosAuthInterceptor(axios: AxiosInstance): AxiosInstance {
    axios.interceptors.request.use(async config => {
        const user = await authenticator.getUser();

        if (user && user.access_token) {
            config.headers['Authorization'] = `Bearer ${user.access_token}`;
            config.headers['Session-State'] = user.session_state;
            config.headers['User-Name'] = user.profile.preferred_username;
        }

        return config;
    });

    return axios;
}

