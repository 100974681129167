import { FC } from "react";
import { Button, Container, Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ConsumerSetting } from "./ConsumerSettingsService";
import { FormField } from "../common/components";

interface ConsumerSettingsNewModalProps {
    setting: ConsumerSetting;
    onClose: () => void;
    onSubmit: (setting: ConsumerSetting) => any;
}

const ConsumerSettingsNewModal: FC<ConsumerSettingsNewModalProps> = (modalProps: ConsumerSettingsNewModalProps) => {
    const { setting, onClose, onSubmit } = modalProps;

    return (
        <Modal isOpen={true} toggle={onClose} size="md">
            <ModalHeader toggle={onClose}>Add Consumer Setting</ModalHeader>
            <ModalBody>
                <Container>
                    <Form>
                        <FormField
                            label="ConsumerKey"
                            type="text"
                            onChange={(val) => setting.consumerKey = (val ?? "")}
                            required/>

                        <FormField
                            label="Exchange"
                            type="text"
                            onChange={(val) => setting.exchange = (val ?? "")}
                            required/>

                        <FormField
                            label="BindingKey"
                            type="text"
                            onChange={(val) => setting.bindingKey = (val ?? "")}
                            required/>
                    </Form>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => {
                        onSubmit({ ...setting });
                        onClose();
                    }}>
                    Submit
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConsumerSettingsNewModal;